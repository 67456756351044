import React, { createContext, useContext, useReducer } from "react";
import { SET_DISCOUNT, DISCOUNTS_RECEIVED } from "../actions/discounts";
import DescuentosService from "../services/DescuentosService";
import DiscountsReducer from "../reducers/DiscountsReducer";
import { ModalContext } from "./ModalContext";

const initialState = {
  descuentos: null,
  descuento: null,
};

export const DiscountsContext = createContext(initialState);

export const DiscountsProvider = ({ children }) => {
  const { alert } = useContext(ModalContext);

  const [state, dispatch] = useReducer(DiscountsReducer, initialState);

  const getDescuentosAdmin = () => {
    DescuentosService.getDescuentosAdmin().then((res) => {
      const { descuentos } = res.data;
      dispatch({ type: DISCOUNTS_RECEIVED, payload: descuentos });
    });
  };

  const getDescuento = (discount_id) => {
    DescuentosService.getDescuentosAdmin().then((res) => {
      const { descuentos } = res.data;
      let index = descuentos.findIndex(
        (descuento) => parseInt(descuento.discount_id) === parseInt(discount_id)
      );
      if (index !== -1) {
        return dispatch({ type: SET_DISCOUNT, payload: descuentos[index] });
      }
      alert("Descuento no encontrado.");
    });
  };

  const setDescuento = (descuento) => {
    dispatch({ type: SET_DISCOUNT, payload: descuento });
  };

  return (
    <DiscountsContext.Provider
      value={{
        ...state,
        getDescuento,
        setDescuento,
        getDescuentosAdmin,
      }}
    >
      {children}
    </DiscountsContext.Provider>
  );
};
