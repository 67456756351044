import {
  PURCHASES_RECEIVED,
  INVOICES_RECEIVED,
  SET_PURCHASE,
} from "../actions/purchases";

const PurchasesReducer = (state, { type, payload }) => {
  switch (type) {
    case PURCHASES_RECEIVED:
      return { ...state, purchases: payload };
    case SET_PURCHASE:
      return { ...state, purchase: payload };
    case INVOICES_RECEIVED:
      return { ...state, invoices: payload };
    default:
      return { ...state };
  }
};

export default PurchasesReducer;
