import {
  APPEND_VIDEOS,
  PURCHASE_NEEDED,
  VIDEOS_RECEIVED,
  AGREGAR_FAVORITO,
  ELIMINAR_FAVORITO,
  FAVORITOS_RECIBIDOS,
  SINGLE_VIDEO_RECEIVED,
} from "../actions/videos";
import { SHOW_SPINNER, HIDE_SPINNER } from "../actions";

const VideosReducer = (state, { type, payload }) => {
  switch (type) {
    case SHOW_SPINNER: {
      return { ...state, spinner: true };
    }
    case HIDE_SPINNER: {
      return { ...state, spinner: false };
    }
    case FAVORITOS_RECIBIDOS:
      return { ...state, favoritos: payload };
    case APPEND_VIDEOS: {
      let videos = state.videos;
      if (videos === null) videos = payload;
      else videos = [...videos, ...payload];
      return { ...state, videos };
    }
    case VIDEOS_RECEIVED: {
      return { ...state, videos: payload };
    }
    case SINGLE_VIDEO_RECEIVED:
      return { ...state, video: payload };
    case AGREGAR_FAVORITO: {
      const videos = [...state.videos];
      const index = videos.findIndex((video) => video.video_id === payload);
      if (index !== -1) {
        videos[index].favorite = 1;
      }
      return { ...state, videos };
    }
    case ELIMINAR_FAVORITO: {
      const videos = [...state.videos];
      const index = videos.findIndex((video) => video.video_id === payload);
      if (index !== -1) {
        videos[index].favorite = 0;
      }
      return { ...state, videos };
    }
    case PURCHASE_NEEDED:
      return { ...state, purchase_needed: true };
    default:
      return { ...state };
  }
};

export default VideosReducer;