import React, { createContext, useContext, useReducer } from "react";
import FirebaseService from "../services/FirebaseService";
import UserReducer from "../reducers/UserReducer";
import {
  LOGIN,
  LOGOUT,
  EDITAR_USUARIO,
  GUARDAR_USUARIO,
  SET_PROPIEDAD_USER,
} from "../actions/auth";
import { SHOW_SPINNER, HIDE_SPINNER } from "../actions";
import { ModalContext } from "./ModalContext";
import CustomerService from "../services/CustomerService";
import { AppConfigContext } from "./AppConfigContext";

const initialState = {
  user: null,
  correo: null,
  password: null,
  telefono: null,
  cuenta: null,
  direccion: null,
  spinner: false,
};

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  const { firebaseConfig } = useContext(AppConfigContext);

  const { success, alert } = useContext(ModalContext);

  const AuthService = FirebaseService(firebaseConfig);

  function handleError(error) {
    dispatch({ type: HIDE_SPINNER });
    if (error.response) {
      if (error.response.status === 409) {
        return alert(
          `Ya tienes una cuenta con nosotros. Por favor, inicia sesión.`
        );
      }
    }
    AuthService.signOut();
  }

  function signIn(email, password) {
    dispatch({ type: SHOW_SPINNER });
    AuthService.signIn(email, password).catch((error) => {
      if (error.code === "auth/user-not-found") {
        alert(
          "Lo sentimos. No encontramos una cuenta con ese correo. ¡Regístrate!"
        );
      }
      if (error.code === "auth/wrong-password") {
        alert("La contraseña es incorrecta. Por favor, intenta de nuevo");
      } else {
        alert(error.toString());
      }
      dispatch({ type: HIDE_SPINNER });
    });
  }

  function signUp(
    { name, email, password, phone, signup_reason, auth_provider },
    callback
  ) {
    dispatch({ type: SHOW_SPINNER });
    CustomerService.getCustomerByPhone(phone)
      .then((res) => {
        const { customer } = res.data;
        if (customer !== null) {
          dispatch({ type: HIDE_SPINNER });
          return alert("Ya existe una cuenta con ese número de teléfono");
        }
        AuthService.signUp(email, password)
          .then((user) => {
            const uid = user.user.uid;
            CustomerService.signUp({
              name,
              email,
              phone,
              uid,
              signup_reason,
              auth_provider,
            })
              .then(() => {
                signIn(email, password);
                if (typeof callback === "function") {
                  callback();
                }
              })
              .catch(handleError);
          })
          .catch(handleError);
      })
      .catch((error) => console.log(error));
  }

  function setupInterceptors() {
    AuthService.setupInterceptors(signOut);
  }

  function userLoggedIn() {
    dispatch({ type: SHOW_SPINNER });
    AuthService.userLoggedIn(
      (user) => {
        CustomerService.getCurrentCustomer()
          .then((res) => {
            let { customer } = res.data;
            dispatch({
              type: LOGIN,
              payload: customer,
            });
            dispatch({ type: HIDE_SPINNER });
          })
          .catch((error) => {
            alert(error);
            AuthService.signOut();
          });
      },
      (error) => {
        if (error) {
          alert(error);
        }
        AuthService.signOut();
        dispatch({ type: HIDE_SPINNER });
      }
    );
  }

  function signOut() {
    AuthService.signOut()
      .then(() => {
        dispatch({ type: LOGOUT });
      })
      .catch((error) => {
        alert(error);
      });
  }

  function getUsuario() {
    CustomerService.getCurrentCustomer().then((res) => {
      const { user, customer } = res.data;
      dispatch({ type: LOGIN, payload: user ? user : customer });
    });
  }

  function editarUsuario() {
    dispatch({ type: EDITAR_USUARIO });
  }

  function cancelEdit() {
    dispatch({ type: GUARDAR_USUARIO });
  }

  function setPropiedadUser(key, value) {
    if (key === "idAdjunto") {
      dispatch({ type: SET_PROPIEDAD_USER, payload: { key: "file", value } });
      if (!value)
        dispatch({ type: SET_PROPIEDAD_USER, payload: { key, value } });
    } else {
      if (key === "telefono") {
        value = String(value).replace(/\D/g, "");
        value = String(value).substring(0, 10);
      }
      dispatch({ type: SET_PROPIEDAD_USER, payload: { key, value } });
    }
  }

  function recoverPassword(email) {
    AuthService.recoverPassword(email).then(() => {
      success("Te hemos enviado un correo para reestablecer tu contraseña.");
    });
  }

  function updateUsuario(usuario) {
    CustomerService.putUser(usuario)
      .then(() => {
        dispatch({ type: GUARDAR_USUARIO });
        success("Perfil actualizado con éxito.");
      })
      .catch((error) => {
        alert(error);
      });
  }

  const verifyEmail = () => {
    AuthService.verifyEmail().then(() => {
      success("Te enviamos un correo para confirmar tu dirección");
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signUp,
        signOut,
        setupInterceptors,
        getUsuario,
        cancelEdit,
        verifyEmail,
        userLoggedIn,
        updateUsuario,
        editarUsuario,
        recoverPassword,
        setPropiedadUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
