
import React, { createContext, useReducer, useContext } from 'react';
import ProgramsService from '../services/ProgramsService';
import ProgramsReducer from '../reducers/ProgramsReducer';
import {
  PROGRAMS_RECEIVED,
  SET_PROGRAM,
  CREATE_PROGRAM,
  SET_PROPERTY_PROGRAM,
} from "../actions/programs";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../actions";

const initialState = {
  programs: null,
  program: null,
};

export const ProgramsContext = createContext(initialState);

export const ProgramsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProgramsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getPrograms = () => {
    ProgramsService.getPrograms()
      .then((response) => {
        const { programs } = response.data;
        dispatch({ type: PROGRAMS_RECEIVED, payload: programs });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleProgram = (program_id) => {
    ProgramsService.getSingleProgram(program_id)
      .then((response) => {
        const { program } = response.data;
        dispatch({ type: SET_PROGRAM, payload: program });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setProgram = (program) => {
    dispatch({ type: SET_PROGRAM, payload: program });
  };

  const createProgram = () => {
    dispatch({ type: CREATE_PROGRAM });
  };

  const setPropertyProgram = (key, value) => {
    dispatch({ type: SET_PROPERTY_PROGRAM, payload: { key, value } });
  };

  const saveProgram = (program, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = ProgramsService.putProgram;
      if(isNaN(parseInt(program.program_id))) {
        service = ProgramsService.postProgram;
      }
      service(program).then(() => {
        success("Program saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteProgram = (program_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    ProgramsService.deleteProgram(program_id).then(() => {
      success("Program deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <ProgramsContext.Provider
    value={{
      ...state,
      setProgram,
      getPrograms,
      saveProgram,
      deleteProgram,
      createProgram,
      getSingleProgram,
      setPropertyProgram,

    }}
  >
    {children}
  </ProgramsContext.Provider>
);
};
