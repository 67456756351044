import React, { createContext, useReducer } from "react";
import { INSTRUCTORS_RECEIVED, SET_INSTRUCTOR } from "../actions/instructors";
import InstructorsReducer from "../reducers/InstructorsReducer";
import InstructorsService from "../services/InstructorsService";

const initialState = {
  instructors: null,
  instructor: null,
};

export const InstructorsContext = createContext(initialState);

export const InstructorsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(InstructorsReducer, initialState);


  const getInstructors = () => {
    InstructorsService.getInstructors().then((res) => {
      const { instructors } = res.data;
      dispatch({ type: INSTRUCTORS_RECEIVED, payload: instructors });
    });
  };

  const getHomeInstructors = () => {
    InstructorsService.getHomeInstructors().then((res) => {
      const { instructors } = res.data;
      dispatch({ type: INSTRUCTORS_RECEIVED, payload: instructors });
    });
  };

  const getInstructor = (instructor_id) => {
    InstructorsService.getSingleInstructor(instructor_id).then((res) => {
      const { instructor } = res.data;
      dispatch({ type: SET_INSTRUCTOR, payload: instructor });
    });
  };

  const setInstructor = (instructor) => {
    dispatch({ type: SET_INSTRUCTOR, payload: instructor });
  };

  return (
    <InstructorsContext.Provider
      value={{
        ...state,
        getInstructor,
        setInstructor,
        getInstructors,
        getHomeInstructors,
      }}
    >
      {children}
    </InstructorsContext.Provider>
  );
};
