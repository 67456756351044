import React, { useContext } from "react";
import ImageInput from "../common/ImageInput";
import { getValue } from "../../utils";
import BirthdateInput from "../common/BirthdateInput";
import { AppConfigContext } from "../../context/AppConfigContext";

const CustomerForm = ({
  user,
  handleCancel,
  handleSubmit,
  setPropiedadUser,
}) => {

  const { S3_ENDPOINT } = useContext(AppConfigContext);


  const getSrc = () => {
    const { file } = user;
    if (file && file !== null) {
      return `${file.name}.${file.type}`;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <ImageInput
        value={getSrc()}
        base_url={S3_ENDPOINT}
        modifier={(picture) => setPropiedadUser("picture", picture)}
      />
      <div className="row mt-3">
        <div className="col-12 col-md-6">
          <label>Nombre:</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(user, "name")}
            onChange={(e) => setPropiedadUser("name", e.target.value)}
          />
        </div>
        <div className="col-12 col-md-6">
          <label>Apellidos:</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(user, "last_name")}
            onChange={(e) => setPropiedadUser("last_name", e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <label>Telefono Celular</label>
          <input
            type="tel"
            className="form-control mb-3"
            value={getValue(user, "phone")}
            onChange={(e) => setPropiedadUser("phone", e.target.value)}
          />
        </div>
        <div className="col-12 col-md-6">
          <label>Instagram:</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(user, "instagram")}
            onChange={(e) => setPropiedadUser("instagram", e.target.value)}
          />
        </div>
      </div>
      <label>Fecha de Nacimiento:</label>
      <BirthdateInput
        value={getValue(user, "birthdate")}
        modifier={(value) => setPropiedadUser("birthdate", value)}
      />

      <div className="row mt-4">
        <div className="col col-md-6">
          <button type="submit" className="btn w-100 btn-primary">
            Guardar
          </button>
        </div>
        <div className="col col-md-6">
          <button
            type="button"
            className="btn w-100 text-muted"
            onClick={handleCancel}
          >
            Cancelar
          </button>
        </div>
      </div>
    </form>
  );
};

export default CustomerForm;
