import React, { useContext, useEffect } from "react";
import PurchasesTable from "../components/purchases/PurchasesTable";
import PurchasesList from "../components/purchases/PurchasesList";
import { PurchasesContext } from "../context/PurchasesContext";
import CancelForm from "../components/purchases/CancelForm";
import PanelHeader from "../components/panel/PanelHeader";
import { ModalContext } from "../context/ModalContext";

const Purchases = () => {
  const { purchases, getPurchases, cancelSubscription } =
    useContext(PurchasesContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getPurchases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmCancel = (purchase) => {
    modalComponent(
      "Precaución",
      <CancelForm
        purchase={purchase}
        handleCancel={clearModal}
        cancelSubscription={cancelSubscription}
      />
    );
  };

  return (
    <div className="container-fluid px-md-4 py-3">
      <PanelHeader title="Mis Compras" />
      <PurchasesTable purchases={purchases} confirmCancel={confirmCancel} />
      <div className="show-mobile">
        <PurchasesList purchases={purchases} confirmCancel={confirmCancel} />
      </div>
    </div>
  );
};

export default Purchases;
