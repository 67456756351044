import React, { createContext, useContext, useReducer } from "react";
import ClassReservationsReducer from "../reducers/ClassReservationsReducer";
import ClassReservationsService from "../services/ClassReservationsService";
import { RESERVATIONS_RECEIVED } from "../actions/class_reservations";
import { AppConfigContext } from "./AppConfigContext";
import { ModalContext } from "./ModalContext";
import { AuthContext } from "./AuthContext";
import { getValue } from "../utils";

const initialState = {
  reservations: null,
  reservation: null,
  updated: false,
};

export const ClassReservationsContext = createContext(initialState);

export const ClassReservatiosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ClassReservationsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const appconfig = useContext(AppConfigContext);

  const { getUsuario } = useContext(AuthContext);

  const getMyReservations = () => {
    ClassReservationsService.getMyReservations().then((res) => {
      const { class_reservations } = res.data;
      dispatch({ type: RESERVATIONS_RECEIVED, payload: class_reservations });
    });
  };

  const cancelReservacion = (class_reservation_id) => {
    ClassReservationsService.cancelReservation(class_reservation_id)
      .then(() => {
        clearModal();
        getUsuario();
        getMyReservations();
        success("¡Reservación cancelada con éxito!");
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412) {
            clearModal();
            alert(
              `Lo sentimos, para cancelar tu clase necesitas al menos ${getValue(appconfig, "cancel_timeframe_value")} horas de anticipación.`
            );
          }
        }
        getMyReservations();
        getUsuario();
      });
  };

  const postReservacion = (clase) => {
    let service;
    if (clase.class_reservation_id) {
      service = ClassReservationsService.putReservation(clase);
    } else {
      service = ClassReservationsService.postReservation(clase);
    }
    service
      .then(() => {
        clearModal();
        success("¡Reservación guardada con éxito!");
        getMyReservations();
        getUsuario();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412 || error.response.status === 409) {
            return alert(
              error.response.data.message
            );
          } 
        }
        getMyReservations();
        getUsuario();
      });
  };

  const postAttend = (class_reservation_id, attend) => {
    ClassReservationsService.postAttend(class_reservation_id, attend).then(
      () => {
        success(attend ? "Asistencia registrada." : "Asistencia cancelada");
      }
    );
  };

  const updateGuestName = (class_reservation_id, name) => {
    ClassReservationsService.updateGuestName(class_reservation_id, name).then(
      () => {
        success("¡Nombre actualizado!");
      }
    );
  };

  const eliminarReservacion = (class_reservation_id) => {
    ClassReservationsService.deleteAsistente(class_reservation_id).then(() => {
      success("¡Reservacion eliminada!");
      clearModal();
    });
  };

  return (
    <ClassReservationsContext.Provider
      value={{
        ...state,
        postAttend,
        postReservacion,
        updateGuestName,
        cancelReservacion,
        getMyReservations,
        eliminarReservacion,
      }}
    >
      {children}
    </ClassReservationsContext.Provider>
  );
};
