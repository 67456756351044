import React from "react";
import utils from "./utils";
import ScheduleDay from "./ScheduleDay";

const ScheduleWeek = ({ location, days }) => {
  const renderDays = () => {
    if (Array.isArray(days)) {
      let currentWeek = [...days];
      if (currentWeek.length > 0) {
        currentWeek = currentWeek.slice(0, 7);
      }
      if (!utils.hasClases(currentWeek)) {
        return (
          <div className="row py-3">
            <p className="mb-0">No hay más clases programadas esta semana.</p>
          </div>
        );
      }
      return currentWeek.map((day) => (
        <ScheduleDay
          day={day}
          key={day.date}
          location={location}
          single_classes={day.details}
        />
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid schedule-mobile-container">
      <div className="schedule-mobile-wrapper ">
        <div className="row">{renderDays()}</div>
      </div>
    </div>
  );
};

export default ScheduleWeek;
