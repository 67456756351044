import React, { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";

const WhatsApp = () => {
  const { whatsapp } = useContext(AppConfigContext);
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={`https://wa.me/521${whatsapp}`}
      className="btn btn-success btn-whatsapp"
    >
      <i className="fab fa-whatsapp"></i>{" "}
    </a>
  );
};
export default WhatsApp;
