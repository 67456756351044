import React, { useContext } from "react";
import { getValue } from "../../utils";
import { AppConfigContext } from "../../context/AppConfigContext";

const LandingAbout = () => {
  const appconfig = useContext(AppConfigContext);
  return (
    <div id="about" className="p-4 py-5 row align-items-center">
      <h2>{getValue(appconfig, "about_title")}</h2>
      <p>{getValue(appconfig, "about_content")}</p>
    </div>
  );
};

export default LandingAbout;
