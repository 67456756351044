import { navigate } from "@reach/router";
import React, { useContext, useEffect } from "react";
import SignUpForm from "../components/auth/SignUpForm";
import { AuthContext } from "../context/AuthContext";
import { AppConfigContext } from "../context/AppConfigContext";

const SignUp = () => {
  const { user } = useContext(AuthContext);
  const { S3_ENDPOINT, bg_login } = useContext(AppConfigContext);

  useEffect(() => {
    if (user !== null && !window.location.href.includes("mystudio")) {
      navigate("mystudio");
    }
  }, [user]);

  const getStyle = () => {
    let style = {};
    if (bg_login && bg_login !== null) {
      style.backgroundImage = `url("${S3_ENDPOINT}/${bg_login}")`;
    }
    return style;
  };


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-6 vh-100 px-0 hide-mobile bg-dark">
          <div className="bg-vertical" style={getStyle()}></div>
        </div>
        <div className="col-12 col-md-6 vh-100">
          <div className="row align-items-center vh-100">
            <div className="container-fluid">
              <h1 className="text-center my-4">¡Regístrate!</h1>
              <SignUpForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
