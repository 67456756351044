import { SingleClassProvider } from "./context/SingleClassContext";
import { CustomerProvider } from "./context/CustomerContext";
import { HomeProvider } from "./context/HomeContext";
import { LocationsProvider } from "./context/LocationsContext";
import { ModalProvider } from "./context/ModalContext";
import { PurchasesProvider } from "./context/PurchasesContext";
import { ClassPackagesProvider } from "./context/ClassPackagesContext";
import { ClassTypeProvider } from "./context/ClassTypesContext";
import { ClassCategoryProvider } from "./context/ClassCategoryContext";
import { CheckoutProvider } from "./context/CheckoutContext";
import { DiscountsProvider } from "./context/DiscountsContext";
import { MetodosProvider } from "./context/MetodosContext";
import { ClassReservatiosProvider } from "./context/ClassReservationsContext";
import { VideosProvider } from "./context/VideosContext";
import { VideoTypeProvider } from "./context/VideoTypeContext";
import { ProgramsProvider } from "./context/ProgramsContext";
import { FiltersProvider } from "./context/FiltersContext";
import { combineComponents } from "./context";
import { InstructorsProvider } from "./context/InstructorsContext";
import Main from "./Main";
import { AppConfigContext, AppConfigProvider } from "./context/AppConfigContext";
import { useContext, useEffect } from "react";
import { AuthProvider } from "./context/AuthContext";
import { AvailableClassesProvider } from "./context/AvailableClassesContext";
import { AddressesProvider } from "./context/AddressesContext";
import { PaymentSourcesProvider } from "./context/PaymentSourcesContext";

const AppContextProvider = combineComponents([
  AuthProvider,
  AvailableClassesProvider,
  ClassReservatiosProvider,
  PaymentSourcesProvider,
  ClassPackagesProvider,
  ClassCategoryProvider,
  SingleClassProvider,
  InstructorsProvider,
  ClassTypeProvider,
  VideoTypeProvider,
  LocationsProvider,
  PurchasesProvider,
  DiscountsProvider,
  AddressesProvider,
  CustomerProvider,
  CheckoutProvider,
  ProgramsProvider,
  MetodosProvider,
  FiltersProvider,
  VideosProvider,
  HomeProvider,
]);

const AppContext = () => {
  const { firebaseConfig, getAppConfig } = useContext(AppConfigContext);

  useEffect(() => {
    getAppConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderApp = () => {
    if (firebaseConfig && firebaseConfig !== null) {
      return (
        <AppContextProvider>
          <Main />
        </AppContextProvider>
      );
    }
  };

  return <div>{renderApp()}</div>;
};

const App = () => {
  return (
    <ModalProvider>
      <AppConfigProvider>
          <AppContext />
      </AppConfigProvider>
    </ModalProvider>
  );
};

export default App;
