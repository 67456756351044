import React, { useContext, useEffect, useState } from "react";
import { PaymentSourcesContext } from "../../context/PaymentSourcesContext";
import PaymentMethodCard from "../paymentMethods/PaymentMethodCard";
import { CheckoutContext } from "../../context/CheckoutContext";
import PaymentSource from "../payment_sources/PaymentSource";
import CheckoutService from "../../services/CheckoutService";
import { handleCheckoutError } from "../../utils/checkout";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";
import StripeCheckout from "../common/StripeCheckout";
import { navigate } from "@reach/router";
import SubmitButton from "../common/SubmitButton";

const CheckoutPaymentMethods = ({ class_package_id, installmentsOptions }) => {
  const [installments, setInstallments] = useState(1);
  const [processing, setProcessing] = useState(false);

  const { user } = useContext(AuthContext);
  const { alert } = useContext(ModalContext);
  const {
    payment_source,
    payment_sources,
    setPaymentSource,
    getPaymentSources,
  } = useContext(PaymentSourcesContext);

  const { descuento, class_package, setPayPal, discountCode } =
    useContext(CheckoutContext);

  useEffect(() => {
    if (user !== null) {
      getPaymentSources();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (descuento !== null && class_package !== null) {
      setPayPal(class_package_id, discountCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descuento]);

  useEffect(() => {
    const paypalButton = document.getElementById("paypal-button");
    if (user !== null && class_package !== null && paypalButton !== null) {
      setPayPal(class_package_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, class_package]);

  const renderPaymentSources = () => {
    if (payment_sources && payment_sources !== null) {
      return payment_sources
        .filter((metodo) => metodo.source_id !== null)
        .map((metodo) => (
          <PaymentSource
            payment_source={metodo}
            paymentMethod={payment_source}
            key={metodo.payment_source_id}
            setPaymentSource={setPaymentSource}
            selectedInstallments={installments}
            installmentsOptions={installmentsOptions}
            setSelectedInstallments={setInstallments}
          />
        ));
    }
  };

  const renderPayPal = () => {
    return (
      <PaymentMethodCard
        name="paypal"
        label="PayPal"
        selected={payment_source}
        setPaymentMethod={setPaymentSource}
      >
        <div
          id="paypal-button"
          style={{
            visibility: payment_source === "paypal" ? "visible" : "hidden",
          }}
        ></div>
      </PaymentMethodCard>
    );
  };

  const handleError = (message) => {
    setProcessing(false);
    alert(message);
  };

  const handleSuccess = (purchase_id) => {
    setProcessing(false);
    navigate(`/gracias/${purchase_id}`);
  };

  const handleSubmit = () => {
    setProcessing(true);
    CheckoutService.postCheckout(class_package_id, discountCode, payment_source)
      .then(async (res) => {
        const { purchase_id } = res.data;
        handleSuccess(purchase_id);
      })
      .catch((error) => handleCheckoutError(error, handleError));
  };

  const renderPayButton = () => {
    if (!["card", "paypal"].includes(payment_source)) {
      return (
        <div className="container-fluid px-0 text-right">
          <SubmitButton label="Pagar Ahora" spinner={processing} onClick={handleSubmit} />
        </div>
      );
    }
  };

  const renderPago = () => {
    if (user !== null) {
      return (
        <div className="oveflow-hidden">
          <h3>Forma de Pago</h3>
          <PaymentMethodCard
            name="card"
            label="Tarjeta de Crédito/Débito"
            selected={payment_source}
            setPaymentMethod={setPaymentSource}
          >
            {payment_source === "card" && (
              <StripeCheckout
                element_id={class_package_id}
                discountCode={discountCode}
                installmentsOptions={installmentsOptions}
              />
            )}
          </PaymentMethodCard>
          {renderPayPal()}
          {renderPayButton()}
        </div>
      );
    }
  };

  return <div>{renderPago()}</div>;
};

export default CheckoutPaymentMethods;
