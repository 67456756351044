import React, { createContext, useReducer } from "react";
import FiltersReducer from "../reducers/FiltersReducer";
import { SET_FILTER } from "../actions/filters";

const initialState = {
  class_category_id: null,
  instructor_id: null,
  class_type_id: null,
  video_type_id: null,
  program_id: null,
  duration: null,
  tag_id: null,
  query: "",
};

export const FiltersContext = createContext(initialState);

export const FiltersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(FiltersReducer, initialState);

  const setFilter = (key, value) => {
    dispatch({ type: SET_FILTER, payload: { key, value } });
  };

  return (
    <FiltersContext.Provider value={{ ...state, setFilter }}>
      {children}
    </FiltersContext.Provider>
  );
};
