import {
  SCHEDULE_RECEIVED,
  SET_SINGLE_CLASS,
  SET_START_DATE,
  SET_END_DATE,
  SET_MONTH,
  SET_WEEK,
} from "../actions/single_class";
import { SHOW_SPINNER, HIDE_SPINNER } from "../actions";

const SingleClassReducer = (state, { type, payload }) => {
  switch (type) {
    case SCHEDULE_RECEIVED:
      return { ...state, days: payload };
    case SET_SINGLE_CLASS:
      return { ...state, single_class: payload };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    case SET_MONTH:
      return { ...state, month: payload };
    case SET_WEEK:
      return { ...state, selectedWeek: payload };
    case SET_START_DATE:
      return { ...state, start_date: payload };
    case SET_END_DATE:
      return { ...state, end_date: payload };
    default:
      return { ...state };
  }
};

export default SingleClassReducer;
