import React, { useContext, useEffect } from "react";
import HomeCoachCard from "../coaches/HomeCoachCard";
import { InstructorsContext } from "../../context/InstructorsContext";

const LandingCoaches = () => {
  const { coaches, getHomeInstructors } = useContext(InstructorsContext);

  useEffect(() => {
    getHomeInstructors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCoaches = () => {
    if (coaches && coaches !== null) {
      return coaches.map((coach) => (
        <HomeCoachCard key={coach.instructor_id} coach={coach} />
      ));
    }
  };

  return (
    <div className="container-fluid py-5 bg-light">
      <h2 className="text-center">Coaches</h2>
      <div className="row">{renderCoaches()}</div>
    </div>
  );
};

export default LandingCoaches;
