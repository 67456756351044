import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import CustomerForm from "./CustomerForm";
import CustomerData from "./CustomerData";

const CustomerInfo = () => {
  const [editMode, setEditMode] = useState(false);

  const { user, getUsuario, updateUsuario, setPropiedadUser } =
    useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    setEditMode(false);
    updateUsuario(user);
  };

  const handleCancel = () => {
    setEditMode(false);
    getUsuario();
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const renderInformacion = () => {
    if (user && user !== null) {
      if (editMode) {
        return (
          <CustomerForm
            user={user}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            setPropiedadUser={setPropiedadUser}
          />
        );
      }
      return <CustomerData user={user} handleEdit={handleEdit} />;
    }
    return <div className="spinner-border"></div>;
  };

  return <div className="card no-scale my-4 p-4 shadow">{renderInformacion()}</div>;
};

export default CustomerInfo;