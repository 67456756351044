import React, { useContext } from "react";
import { Link } from "@reach/router";
import { getValue } from "../../utils";
// import amex from "../../assets/images/amex.png";
// import visa from "../../assets/images/visa.png";
// import mastercard from "../../assets/images/mastercard.png";
import { AppConfigContext } from "../../context/AppConfigContext";

const Footer = () => {
  const appconfig = useContext(AppConfigContext);
  return (
    <div className="footer bg-primary-light py-5">
      {/*<div className="container px-0 mb-5">
        <img src={getValue(appconfig, "footer_logo")} alt="logo footer" className="logo-footer" />
      </div>*/}
      <div className="container px-md-0 text-dark">
        <div className="row">
          <div className="col-12 col-md-4 my-3">
            <h4 className="bold">CONTACTO</h4>
            <a
              href={`mailto:${getValue(appconfig, "email")}`}
              className="text-dark no-decoration d-inline-block mb-3"
              rel="noreferrer"
              target="_blank"
            >
              <i className="fa fa-envelope me-2 text-primary"></i>{" "}
              {getValue(appconfig, "email")}
            </a>
            <a
              href={getValue(appconfig, "facebook")}
              className="text-dark no-decoration"
              rel="noreferrer"
              target="_blank"
            >
              <p>
                <i className="fab fa-facebook me-2 text-primary"></i>{" "}
                {getValue(appconfig, "facebook")}
              </p>
            </a>
            <a
              href={getValue(appconfig, "instagram")}
              className="text-dark no-decoration"
              rel="noreferrer"
              target="_blank"
            >
              <p>
                <i className="fab fa-instagram me-2 text-primary"></i> @
                {getValue(appconfig, "instagram")}
              </p>
            </a>
          </div>
          <div className="col-12 col-md-4 my-3 pe-5">
            <h4 className="bold">UBICACIÓN</h4>
            <p>
              <i className="fa fa-map-pin text-primary me-1"></i>{" "}
              {getValue(appconfig, "location")}
            </p>
          </div>
          <div className="col-12 col-md-4 my-3">
            <h4 className="mt-4 bold">INFORMACIÓN</h4>
            <Link
              to="/terminos-y-condiciones"
              className="text-dark hover-primary d-block mb-2 no-decoration"
            >
              Términos y Condiciones
            </Link>
            <Link
              to="/privacidad"
              className="text-dark hover-primary d-block mb-2 no-decoration"
            >
              Aviso de Privacidad
            </Link>
            {/*<h4 className="bold">ACEPTAMOS</h4>
            <img src={visa} alt="Visa" className="payment-method me-2" />
            <img src={amex} alt="American Express" className="payment-method me-2" />
            <img src={mastercard} alt="Mastercard" className="payment-method me-2" />*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
