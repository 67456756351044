import { getArgs } from "../utils";
import api from "./api";

const route = "/videos";

const VideosService = {
  getVideos: (params) => api.get(`${route}?${getArgs(params)}`),
  getUpcomingVideos: (params) =>
    api.get(`${route}/upcoming?${getArgs(params)}`),
  getVideosByTag: (tag_id, filters) =>
    api.get(`${route}/tag/${tag_id}?${getArgs(filters)}`),
  getVideosByProgram: (url, filters) =>
    api.get(`${route}/program/${url}?${getArgs(filters)}`),
  getFavoritos: (query) => api.get(`/favorites?${getArgs(query).slice(1)}`),
  getSingleVideo: (video_id) => api.get(`${route}/${video_id}`),
  postFavorito: (video_id) => api.post("/favorites", { video_id }),
  postVideoRating: (video_rating) =>
    api.post(`${route}/rating`, { ...video_rating }),
  postVideoRatingEmail: (video_id) =>
    api.post(`${route}/rating/email`, { video_id }),
  completarVideo: (video_id) => api.put(`${route}/${video_id}/complete`),
  eliminarCompletado: (video_id) => api.put(`${route}/${video_id}/uncomplete`),
  deleteFavorito: (video_id) => api.delete(`/favorites/${video_id}`),
};

export default VideosService;