import api from "./api";

const route = "/purchases";

const PurchasesService = {
  getPurchases: () => api.get(route),
  getPurchase: (purchase_id) => api.get(`${route}/${purchase_id}`),
  cancelSubscription: (purchase_id, reason) =>
    api.post(`${route}/cancel/${purchase_id}`, { reason }),
};

export default PurchasesService;
