import React, { createContext, useReducer } from "react";
import PaquetesReducer from "../reducers/PaquetesReducer";
import PaquetesService from "../services/PaquetesService";
import { CLASS_PACKAGES_RECEIVED } from "../actions/class_packages";

const initialState = {
  class_packages: null,
  class_package: null,
};

export const ClassPackagesContext = createContext(initialState);

export const ClassPackagesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PaquetesReducer, initialState);

  const getPaquetes = () => {
    PaquetesService.getPaquetes().then((res) => {
      const { class_packages } = res.data;
      dispatch({ type: CLASS_PACKAGES_RECEIVED, payload: class_packages });
    });
  };

  const getPaquetesEspeciales = () => {
    PaquetesService.getPaquetesEspeciales().then((res) => {
      const { class_packages } = res.data;
      dispatch({ type: CLASS_PACKAGES_RECEIVED, payload: class_packages });
    });
  };

  const getPaquetesOnline = () => {
    PaquetesService.getOnline().then((res) => {
      const { class_packages } = res.data;
      dispatch({ type: CLASS_PACKAGES_RECEIVED, payload: class_packages });
    });
  };

  const getAllPaquetes = () => {
    PaquetesService.getAllPaquetes().then((res) => {
      const { class_packages } = res.data;
      dispatch({ type: CLASS_PACKAGES_RECEIVED, payload: class_packages });
    });
  };
  return (
    <ClassPackagesContext.Provider
      value={{
        ...state,
        getPaquetes,
        getAllPaquetes,
        getPaquetesOnline,
        getPaquetesEspeciales,
      }}
    >
      {children}
    </ClassPackagesContext.Provider>
  );
};
