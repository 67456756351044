import { SET_DISCOUNT, DISCOUNTS_RECEIVED } from "../actions/discounts";

const DiscountsReducer = (state, { type, payload }) => {
  switch (type) {
    case DISCOUNTS_RECEIVED:
      return { ...state, descuentos: payload };
    case SET_DISCOUNT:
      return { ...state, descuento: payload };
    default:
      return { ...state };
  }
};

export default DiscountsReducer;
