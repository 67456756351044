export const menuitems = [
  {
    name: "Calendario",
    handle: "/mystudio/presencial",
  },
  {
    name: "Reservaciones",
    handle: "/mystudio/reservaciones",
  },
  {
    name: "Paquetes",
    handle: "/mystudio/paquetes",
  },
];

export const cuenta = [
  {
    name: "Mi Información",
    handle: "/mystudio/informacion",
  },
  {
    name: "Mis Compras",
    handle: "/mystudio/pagos",
  },
];

export const adminitems = [
  {
    name: "Clases",
    handle: "mystudio/admin/asistentes",
  },
  {
    name: "Clientes",
    handle: "mystudio/admin/clientes",
  },
];

export const coachitems = [
  {
    name: "Clases",
    handle: "mystudio/admin/asistentes",
  },
];
