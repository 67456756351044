import React, { useContext } from "react";
import { getValue } from "../../utils";
import { AppConfigContext } from "../../context/AppConfigContext";

const LandingHero = () => {
  const appconfig = useContext(AppConfigContext);
  const { filesBaseSrc } = appconfig;

  const renderHeroResource = () => {
    const imageSrc = `${filesBaseSrc}/${appconfig.hero_image}`;
    const videoSrc = `${filesBaseSrc}/${appconfig.hero_video}`;

    if(getValue(appconfig, 'hero_resource') === 'video') {
      return (
        <video
          loop
          muted
          autoPlay
          playsInline
          data-autoplay
          data-object-fit="cover"
          className="mw-100 w-100 home-video"
          poster={`${filesBaseSrc}/${getValue(
            appconfig,
            "landing_video_cover"
          )}`}
        >
          <source src={videoSrc} />
        </video>
      )
    }else if(getValue(appconfig, 'hero_resource') === 'image') {
      return (
        <img
          alt="hero image"
          src={imageSrc}
          data-object-fit="cover"
          className="mw-100 w-100 home-video"
        />
      )
    }
  }
  return (
    <div id="hero" className="row mx-0 align-items-center position-relative">
      {renderHeroResource()}
      <div className="container text-center">
        <h1 className="display-1 text-shadow text-white futura-bold-italic">
          {getValue(appconfig, "hero_title")}
        </h1>
        <p className="tagline text-white mb-md-5 bold">
          {getValue(appconfig, "hero_tagline")}
        </p>
      </div>
    </div>
  );
};

export default LandingHero;
