import React, { createContext, useContext, useReducer } from "react";
import VideosReducer from "../reducers/VideosReducer";
import VideosService from "../services/VideosService";
import { SHOW_SPINNER, HIDE_SPINNER } from "../actions";
import {
  VIDEOS_RECEIVED,
  PURCHASE_NEEDED,
  AGREGAR_FAVORITO,
  ELIMINAR_FAVORITO,
  FAVORITOS_RECIBIDOS,
  SINGLE_VIDEO_RECEIVED,
} from "../actions/videos";
import { ModalContext } from "./ModalContext";

const initialState = {
  favoritos: null,
  programs: null,
  videos: null,
  video: null,
  spinner: false,
};

export const VideosContext = createContext(initialState);

export const VideosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(VideosReducer, initialState);

  const { alert, success } = useContext(ModalContext);

  const getVideos = (filters) => {
    dispatch({ type: SHOW_SPINNER });
    VideosService.getVideos(filters)
      .then((res) => {
        const { videos } = res.data;
        dispatch({ type: HIDE_SPINNER });
        dispatch({ type: VIDEOS_RECEIVED, payload: videos });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        if (error.response) {
          if (error.response.status === 412) {
            dispatch({ type: PURCHASE_NEEDED });
          }
        }
      });
  };

  const getVideosByProgram = (url, filters) => {
    VideosService.getVideosByProgram(url, filters)
      .then((res) => {
        const { videos } = res.data;
        dispatch({ type: VIDEOS_RECEIVED, payload: videos });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412) {
            dispatch({ type: PURCHASE_NEEDED });
          }
        }
      });
  };

  const getVideosByTag = (tag_id, filters) => {
    VideosService.getVideosByTag(tag_id, filters)
      .then((res) => {
        const { videos } = res.data;
        dispatch({ type: VIDEOS_RECEIVED, payload: videos });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412) {
            dispatch({ type: PURCHASE_NEEDED });
          }
        }
      });
  };

  const getFavoritos = (filters) => {
    VideosService.getFavoritos(filters).then((res) => {
      const { favorites } = res.data;
      dispatch({ type: FAVORITOS_RECIBIDOS, payload: favorites });
    });
  };

  const clearFavoritos = () => {
    dispatch({ type: FAVORITOS_RECIBIDOS, payload: null });
  };

  const getAllVideos = (query) => {
    VideosService.getAllVideos(query).then((res) => {
      const { videos } = res.data;
      dispatch({ type: VIDEOS_RECEIVED, payload: videos });
    });
  };

  const getSingleVideo = (video_id) => {
    VideosService.getSingleVideo(video_id)
      .then((res) => {
        const { video } = res.data;
        dispatch({ type: SINGLE_VIDEO_RECEIVED, payload: video });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            alert("Lo sentimos, no hemos encontrado este video.");
          }
        }
      });
  };

  const agregarFavorito = (video_id) => {
    VideosService.postFavorito(video_id).then(() => {
      dispatch({ type: AGREGAR_FAVORITO, payload: video_id });
      success("¡Favorito agregado!");
    });
  };

  const eliminarFavorito = (video_id) => {
    VideosService.deleteFavorito(video_id).then(() => {
      dispatch({ type: ELIMINAR_FAVORITO, payload: video_id });
      success("¡Favorito eliminado!");
    });
  };

  const clearVideos = () => {
    dispatch({ type: VIDEOS_RECEIVED, payload: null });
  };

  return (
    <VideosContext.Provider
      value={{
        ...state,
        getVideos,
        clearVideos,
        getAllVideos,
        getFavoritos,
        getVideosByTag,
        clearFavoritos,
        getSingleVideo,
        agregarFavorito,
        eliminarFavorito,
        getVideosByProgram,
      }}
    >
      {children}
    </VideosContext.Provider>
  );
};
