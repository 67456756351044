import React, { useContext } from "react";
import Shop from "../../views/Shop";

const LandingPackages = () => {

  return (
    <div id="landing-packages" className="p-4 py-5 row align-items-center">
      <Shop/>
    </div>
  );
};

export default LandingPackages;
