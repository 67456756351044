import React, { useContext } from "react";
import { Link } from "@reach/router";
import { getValue } from "../../utils";
import { AppConfigContext } from "../../context/AppConfigContext";
import { useGetAppConfigValue } from "../../hooks/useGetAppConfigValue";

const PanelNavbar = ({ user, signOut }) => {
  const { S3_ENDPOINT, navbar_logo } = useContext(AppConfigContext);
  const { allowRestrictedClassTypes } = useGetAppConfigValue();

  return (
    <nav
      id="panel-navbar"
      className="navbar navbar-expand-lg navbar-light bg-primary-light shadow-sm hide-mobile"
    >
      <div className="container-fluid">
        <Link to="/" className="navbar-brand py-2 ms-3">
          <img
            src={`${S3_ENDPOINT}/${navbar_logo}`}
            className="logo-navbar"
            alt="logo"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div>
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to="/mystudio/presencial" className="nav-link">
                Presenciales
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/mystudio/paquetes" className="nav-link">
                Paquetes
              </Link>
            </li>
            <li className="nav-item dropdown">
              <span
                className="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {getValue(user, "name")}{" "}
                <i className="ms-2 fa fa-user-circle"></i>
              </span>
              <ul
                className="dropdown-menu user-menu"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <Link to="/mystudio/informacion" className="dropdown-item">
                    Mi Información
                  </Link>
                </li>
                <li>
                  <Link to="/mystudio/membresias" className="dropdown-item">
                    Mis Compras
                  </Link>
                </li>
                <li>
                  <Link to="/mystudio/pagos" className="dropdown-item">
                    Mis Pagos
                  </Link>
                </li>
                {allowRestrictedClassTypes && (
                  <li>
                    <Link to="/mystudio/creditos" className="dropdown-item">
                      Mis Créditos
                    </Link>
                  </li>
                )}
                <li>
                  <Link to="/mystudio/reservaciones" className="dropdown-item">
                    Mis Reservaciones
                  </Link>
                </li>
                <li className="mt-3">
                  <button
                    className="dropdown-item text-danger"
                    onClick={signOut}
                  >
                    Salir <i className="ms-2 fa fa-sign-out-alt"></i>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default PanelNavbar;
