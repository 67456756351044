import React, { useContext } from "react";
import { navigate } from "@reach/router";
import { AppConfigContext } from "../../context/AppConfigContext";

const HomeClassTypeCard = ({ class_type }) => {
  const { S3_ENDPOINT, navbar_logo } = useContext(AppConfigContext);

  const getSrc = () => {
    if (class_type.file && class_type.file !== null) {
      return `${S3_ENDPOINT}/${class_type.file.name}.${class_type.file.type}`;
    }
    return `${S3_ENDPOINT}/${navbar_logo}`;
  };
  return (
    <div
      className="col-6 col-md-3 mb-3 cursor-pointer"
      onClick={() => navigate(`/class_type/${class_type.class_type_id}`)}
    >
      <div className="shadow-sm">
        <img src={getSrc()} className="class-type-home" alt={class_type.name} />
        <div className="p-3 border class-type-title">
          <h4 className="mb-0">{class_type.name}</h4>
        </div>
      </div>
    </div>
  );
};

export default HomeClassTypeCard;
