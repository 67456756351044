import {
  SET_CLASS_PACKAGE,
  CLASS_PACKAGES_RECEIVED,
} from "../actions/class_packages";

const ClassPackagesReducer = (state, { type, payload }) => {
  switch (type) {
    case CLASS_PACKAGES_RECEIVED:
      return { ...state, class_packages: payload };
    case SET_CLASS_PACKAGE:
      return { ...state, class_package: payload };
    default:
      return { ...state };
  }
};

export default ClassPackagesReducer;
