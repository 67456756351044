import React, { useContext, useEffect } from "react";
import Footer from "../components/global/Footer";
import { CheckoutContext } from "../context/CheckoutContext";
import CheckoutHeader from "../components/checkout/CheckoutHeader";
import CheckoutAccount from "../components/checkout/CheckoutAccount";
import CheckoutDiscount from "../components/checkout/CheckoutDiscount";
import CheckoutClassPackage from "../components/checkout/CheckoutClassPackage";
import CheckoutPaymentMethods from "../components/checkout/CheckoutPaymentMethods";
import CheckoutBillingDisclaimer from "../components/checkout/CheckoutBillingDisclaimer";
import { getValue } from "../utils";

const Checkout = ({ class_package_id }) => {

  //Checkout
  const {
    setDescuento,
    class_package,
    setPaymentSource,
    payment_source_id,
  } = useContext(CheckoutContext);

  useEffect(() => {
    return () => {
      setDescuento(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderBillingDisclaimer = () => {
    if (getValue(class_package, "is_subscription")) {
      return <CheckoutBillingDisclaimer />;
    }
  };

  return (
    <div className="container-fluid px-0">
      <CheckoutHeader />
      <div className="container">
        <div className="row pt-4 pb-5">
          <div className="col-12 col-md-6 col-xl-4 my-2">
            <CheckoutClassPackage class_package_id={class_package_id} />
            <CheckoutDiscount class_package_id={class_package_id} />
          </div>
          <div className="col-12 col-md-6 col-xl-8 my-2">
            <CheckoutAccount />
            <CheckoutPaymentMethods
              class_package_id={class_package_id}
              paymentMethod={payment_source_id}
              setPaymentMethod={setPaymentSource}
            />
          </div>
        </div>
        {renderBillingDisclaimer()}
      </div>
      <Footer />
    </div>
  );
};

export default Checkout;
