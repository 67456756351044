import React, { useContext } from "react";
import { AppConfigContext } from "../context/AppConfigContext";
import LandingAbout from "../components/landing/LandingAbout";
import LandingSchedule from "../components/landing/LandingSchedule";
import LandingClassTypes from "../components/landing/LandingClassTypes";
import LandingCoaches from "../components/landing/LandingCoaches";
import LandingHero from "../components/landing/LandingHero";
import LandingPackages from "../components/landing/LandingPackages";
import { getValue } from "../utils";

const Landing = () => {
  const appconfig = useContext(AppConfigContext);

  const sections = [
    {
      title: "Hero",
      enabled: getValue(appconfig, "hero_enabled", "boolean"),
      order: appconfig["hero_order"],
      component: <LandingHero key={'hero'}/>
    },
    {
      title: "About",
      enabled: getValue(appconfig, "about_enabled", "boolean"),
      order: appconfig["about_order"],
      component: <LandingAbout key={'about'}/>
    },
    {
      title: "Tipos de Clase",
      enabled: getValue(appconfig, "class_type_enabled", "boolean"),
      order: appconfig["class_types_order"],
      component: <LandingClassTypes key={'class_types'}/>
    },
    {
      title: "Coaches",
      enabled: getValue(appconfig, "coaches_enabled", "boolean"),
      order: appconfig["coaches_order"],
      component: <LandingCoaches key={'coaches'}/>
    },
    {
      title: "Paquetes",
      enabled: getValue(appconfig, "packages_enabled", "boolean"),
      order: appconfig["packages_order"],
      component: <LandingPackages key={'packages'}/>
    },
    {
      title: "Clases Presenciales",
      enabled: getValue(appconfig, "schedule_enabled", "boolean"),
      order: appconfig["schedule_enabled"],
      component: <LandingSchedule key={'schedule'}/>
    }
  ];

  const renderLandingSections = () => {
    const filteredSections = [...sections].filter(section => {
      if(section.enabled) return section;
    });

    const orderedSections = filteredSections.sort((a, b) => {
      return a.order - b.order;
    });

    return orderedSections.map(section => {
      return section.component;
    });
  }

  return (
    <div id="landing">
      {renderLandingSections()}
    </div>
  );
};

export default Landing;
