import { Link } from "@reach/router";
import React, { useContext, useEffect } from "react";
import Schedule from "../components/schedule/Schedule";
import { ClassTypeContext } from "../context/ClassTypesContext";
import ClassTypeCard from "../components/classTypes/ClassTypeCard";
import { ClassReservationsContext } from "../context/ClassReservationsContext";
import { AvailableClassesContext } from "../context/AvailableClassesContext";
import { useGetAppConfigValue } from "../hooks/useGetAppConfigValue";

const Presenciales = () => {
  const { getMyReservations } = useContext(ClassReservationsContext);
  const { class_types, getClassTypes } = useContext(ClassTypeContext);
  const { available_classes, getAvailableClasses } = useContext(AvailableClassesContext);
  const { allowRestrictedClassTypes } = useGetAppConfigValue();

  useEffect(() => {
    getClassTypes();
    getMyReservations();
    getAvailableClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderClassTypes = () => {
    if (Array.isArray(class_types)) {
      return class_types.map((class_type) => (
        <ClassTypeCard key={class_type.class_type_id} class_type={class_type} />
      ));
    }
  };

  const renderAvailableCredits = () => {
    if(allowRestrictedClassTypes) {
      return (
        <Link
          to="/mystudio/creditos"
          className="h3 my-2 text-black d-block"
        >
          Disponibles {available_classes?.length}
        </Link>
      )
    } else {
      return (
        <h4 className="my-2">Disponibles: {available_classes?.length}</h4>
      )
    }
  }

  return (
    <div className="container-fluid">
      <div className="row align-items-start border-bottom pb-3 mb-4">
        <div className="col-12 col-md-8">
          <h1 className="h2">Calendario</h1>
          <p>Reserva tus clases presenciales</p>
        </div>
        <div className="col-12 col-md-4 text-right mobile-left">
          {renderAvailableCredits()}
          <Link
            to="/mystudio/reservaciones"
            className="btn btn-outline-primary"
          >
            Ver Mis Reservaciones
          </Link>
        </div>
      </div>
      <Schedule />
      <div className="row">
        {renderClassTypes()}
      </div>
    </div>
  );
};

export default Presenciales;
