import { useContext } from "react";
import { AppConfigContext } from "../context/AppConfigContext";
import { getValue } from "../utils";

export function useGetAppConfigValue() {
  const appconfig = useContext(AppConfigContext);

  const getAppConfigValue = (key, type) => {
    const value = getValue(appconfig, key, type);
    return value;
  }

  const allowRestrictedClassTypes = getAppConfigValue('allow_restricted_class_types', "boolean");


  return {
    appconfig,
    getAppConfigValue,
    allowRestrictedClassTypes
  }
}