import React, { createContext, useContext, useReducer } from "react";
import {
  SET_PURCHASE,
  INVOICES_RECEIVED,
  PURCHASES_RECEIVED,
} from "../actions/purchases";
import { ModalContext } from "./ModalContext";
import PurchasesReducer from "../reducers/PurchasesReducer";
import InvoicesService from "../services/InvoicesService";
import PurchasesService from "../services/PurchasesService";

const initialState = {
  purchases: null,
  purchase: null,
  invoices: null,
};

export const PurchasesContext = createContext(initialState);

export const PurchasesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PurchasesReducer, initialState);

  const { success, clearModal } = useContext(ModalContext);

  const getPurchases = () => {
    PurchasesService.getPurchases().then((res) => {
      const { purchases } = res.data;
      dispatch({ type: PURCHASES_RECEIVED, payload: purchases });
    });
  };

  const getInvoices = () => {
    InvoicesService.getInvoices().then((res) => {
      const { invoices } = res.data;
      dispatch({ type: INVOICES_RECEIVED, payload: invoices });
    });
  };

  const getPurchase = (purchase_id) => {
    PurchasesService.getPurchase(purchase_id).then((res) => {
      const { purchase } = res.data;
      dispatch({ type: SET_PURCHASE, payload: purchase });
    });
  };

  const cancelSubscription = (purchase_id, reason) => {
    PurchasesService.cancelSubscription(purchase_id, reason).then((res) => {
      success("¡Suscripción cancelada!");
      getPurchases();
      clearModal();
    });
  };

  return (
    <PurchasesContext.Provider
      value={{
        ...state,
        getPurchase,
        getPurchases,
        getInvoices,
        cancelSubscription,
      }}
    >
      {children}
    </PurchasesContext.Provider>
  );
};
