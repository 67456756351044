import React from "react";

const PlannerColorLegend = () => {
  return (
    <div className="container-fluid px-2 mb-2">
      <div className="row">
        <div className="col-4">
          <div className="row">
            <div className="col-3 col-md-2 px-0">
              <div className="color-legend br-25 bg-light pe-0"></div>
            </div>
            <div className="col-9 col-md-10 ps-0">
              <span className="small">Disponible</span>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="row">
            <div className="col-3 col-md-2 px-0">
              <div className="color-legend br-25 bg-primary pe-0"></div>
            </div>
            <div className="col-9 col-md-10 ps-0">
              <span className="small">Reservada</span>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="row">
            <div className="col-3 col-md-2 px-0">
              <div className="color-legend br-25 bg-primary-light pe-0"></div>
            </div>
            <div className="col-9 col-md-10 ps-0">
              <span className="small">Sin Lugares</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PlannerColorLegend;
