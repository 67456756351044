import React from "react";
import Schedule from "../schedule/Schedule";

const LandingSchedule = () => {
  return (
    <div className="container-fluid text-center pt-4 mt-5" id="calendario">
      <h2 className="mt-3">Calendario de Clases</h2>
      <Schedule isHome={true} hidePrevious />
    </div>
  );
};

export default LandingSchedule;
