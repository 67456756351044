import React from "react";
import PanelHeader from "../components/panel/PanelHeader";
import CustomerInfo from "../components/customers/CustomerInfo";
import CustomerAddress from "../components/customers/CustomerAddress";


const MiInformacion = () => {
  return (
    <div className="container px-4 px-md-0 py-4">
      <PanelHeader title="Mi Informacion" />
      <CustomerInfo />
      <CustomerAddress />
    </div>
  );
};

export default MiInformacion;