import { RESERVATIONS_RECEIVED } from "../actions/class_reservations";

const ReservationsReducer = (state, { type, payload }) => {
  switch (type) {
    case RESERVATIONS_RECEIVED:
      return { ...state, reservations: payload };
    default:
      return { ...state };
  }
};

export default ReservationsReducer;
