import { CLASS_TYPES_RECEIVED, SET_CLASS_TYPE } from "../actions/class_types";

const ClassTypesReducer = (state, { type, payload }) => {
  switch (type) {
    case CLASS_TYPES_RECEIVED:
      return { ...state, class_types: payload };
    case SET_CLASS_TYPE:
      return { ...state, class_type: payload };
    default:
      return { ...state };
  }
};

export default ClassTypesReducer;
