import React, { useEffect } from "react";
import { Router } from "@reach/router";
import Home from "./views/Home";
import Login from "./views/Login";
import SignUp from "./views/SignUp";
import Recuperar from "./views/Recuperar";
import Panel from "./views/Panel";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import Modal from "./components/common/Modal";
import SuccessAlert from "./components/common/SuccessAlert";
import ErrorAlert from "./components/common/ErrorAlert";
import Gracias from "./views/Gracias";
import Loading from "./views/Loading";
import moment from "moment";
import Checkout from "./views/Checkout";
import Paquetes from "./views/Paquetes";
import Terms from "./components/global/Terms";
import PreguntasFrecuentes from "./components/global/PreguntasFrecuentes";
import Ubicacion from "./components/global/Location";
import CoachPage from "./views/pages/CoachPage";
import WhatsApp from "./components/global/WhatsApp";
import { AppConfigContext } from "./context/AppConfigContext";
import { handleColors, handleMetaTags } from "./utils/appconfig";
import Calendario from "./views/Calendario";

const Main = () => {
  const { user, userLoggedIn } = useContext(AuthContext);
  const appconfig = useContext(AppConfigContext);

  useEffect(() => {
    userLoggedIn();
    moment.updateLocale("es", {
      weekdays: "Lun_Mar_Mie_Jue_Vie_Sab_Dom".split("_"),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appconfig) {
      handleColors(appconfig);
      handleMetaTags(appconfig);
    }
  }, [appconfig]);

  return (
    <div className="container-fluid px-0">
      <Router>
        <Home path="/*" />
        <Login path="/entrar" />
        <Terms path="/terminos" />
        <SignUp path="/registro" />
        <Paquetes path="/paquetes" />
        <Ubicacion path="/ubicacion" />
        <Recuperar path="/recuperar" />
        <Calendario path="/calendario" />
        <CoachPage path="/coach/:coach_id" />
        <Gracias path="/gracias/:purchase_id" />
        <Checkout path="/checkout/:class_package_id" />
        <PreguntasFrecuentes path="/preguntas-frecuentes" />
        {user !== null ? (
          <Panel path="mystudio/*" />
        ) : (
          <Loading path="mystudio/*" />
        )}
      </Router>
      <WhatsApp />
      <Modal />
      <SuccessAlert />
      <ErrorAlert />
    </div>
  );
};

export default Main;
