import { SET_LOCATION, LOCATIONS_RECEIVED } from "../actions/locations";

const LocationsReducer = (state, { type, payload }) => {
  switch (type) {
    case LOCATIONS_RECEIVED:
      return { ...state, locations: payload };
    case SET_LOCATION:
      return { ...state, location: payload };
    default:
      return { ...state };
  }
};

export default LocationsReducer;
