import React, { useContext, useEffect } from "react";
import { ClassTypeContext } from "../../context/ClassTypesContext";
import HomeClassTypeCard from "../classTypes/HomeClassTypeCard";

const LandingClassTypes = () => {
  const { class_types, getClassTypes } = useContext(ClassTypeContext);

  useEffect(() => {
    getClassTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderClassTypes = () => {
    if (Array.isArray(class_types)) {
      let class_types_render = class_types.filter(
        (class_type) => class_type.file !== null
      );
      if (class_types_render.length > 0) {
        return (
          <div className="container-fluid py-5 bg-white">
            <h2 className="text-center">Tipos de Clases</h2>
            <div className="row">
              {class_types_render.map((class_type) => (
                <HomeClassTypeCard
                  key={class_type.class_type_id}
                  class_type={class_type}
                />
              ))}
            </div>
          </div>
        );
      }
    }
  };

  return (
    <div id="class_types" className="bg-light container-fluid">
      {renderClassTypes()}
    </div>
  );
};

export default LandingClassTypes;
