import React, { useContext, useState } from "react";
import { Link } from "@reach/router";
import MobileMenuItem from "./MobileMenuItem";
import { menuitems, cuenta } from "../../utils/menu";
import { AppConfigContext } from "../../context/AppConfigContext";

const MobileMenu = ({ signOut }) => {
  const [display, setDisplay] = useState(false);

  const { S3_ENDPOINT, navbar_logo } = useContext(AppConfigContext);

  return (
    <>
      <nav className="panel-mobile-menu navbar navbar-expand-lg navbar-light bg-primary-light py-2 shadow-sm">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={`${S3_ENDPOINT}/${navbar_logo}`} alt="logo" className="logo-navbar" />
          </Link>
          <button
            className="navbar-toggler ms-auto"
            type="button"
            onClick={() => setDisplay(!display)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>
      <div
        className="bg-light w-100 vh-100 drawer-menu"
        style={{
          position: "absolute",
          left: display ? 0 : "-100vw",
        }}
      >
        <ul className="navbar-nav pt-4 px-3">
          <h3 className="ps-2 border-bottom pb-3">Menú</h3>
          {menuitems.map(({ name, handle }) => (
            <MobileMenuItem
              key={name}
              name={name}
              handle={handle}
              setDisplay={setDisplay}
            />
          ))}
          <h3 className="ps-2 mt-4 border-bottom pb-3">Mi Cuenta</h3>
          {cuenta.map(({ name, handle }) => (
            <MobileMenuItem
              key={name}
              name={name}
              handle={handle}
              setDisplay={setDisplay}
            />
          ))}
          <li className="nav-item mobile mt-5">
            <button
              className="btn btn-link nav-link text-danger"
              onClick={signOut}
            >
              <i className="fa fa-sign-out-alt fa-flip-horizontal ps-2"></i>{" "}
              Salir
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MobileMenu;
