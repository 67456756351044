import moment from "moment";

const getMonth = (month) => {
  if (isNaN(month)) {
    month = moment().local().month();
  }
  return month;
};

const getMonthStart = (month) => {
  const localMonth = getMonth(month);
  return moment(localMonth, "M").startOf("month").startOf("isoWeek").week();
};

const hasClases = (days) => {
  if (days && days !== null) {
    let total = 0;
    days.forEach((day) => {
      total += day.details.length;
    });
    return total > 0;
  }
  return false;
};

const getClassTypes = (class_types, category) => {
  if (
    category &&
    category !== null &&
    category !== "" &&
    Array.isArray(class_types)
  ) {
    class_types = class_types.filter(
      (classType) =>
        parseInt(classType.class_category_id) === parseInt(category)
    );
  }
  return class_types;
};

const getLimitWeeks = (days, month) => {
  let week;
  let currentWeek;
  const weeksNumber = Math.ceil(days.length / 7);
  let weeks = weeksNumber;
  const localMonth = getMonth();
  let currentMonth = getMonth(month);
  if (currentMonth === localMonth) {
    const startCurrentWeek = moment().local().startOf("isoWeek").week();
    const startCurrentMonth = getMonthStart();
    const currentWeekIndex = startCurrentWeek - startCurrentMonth;
    currentWeek = currentWeekIndex;
    if (currentWeekIndex > 0) {
      week = currentWeekIndex;
    }
  }
  if (!week) {
    week = 0;
  }
  return { week, weeks, currentWeek };
};

const getStartDate = (layout) => {
  return moment()
    .startOf(layout === "week" ? "isoWeek" : "month")
    .format("YYYY-MM-DD");
};

const getEndDate = (layout, start_date) => {
  let end_date = moment(start_date).add(1, layout).format("YYYY-MM-DD");
  return end_date;
};

const getCurrentWeek = (days, month, selectedWeek) => {
  let currentMonth = getMonth(month);
  let weekStart = moment(currentMonth, "M");
  if (month > 11) {
    weekStart = weekStart.add(1, "year");
  }
  if (weekStart.isoWeekday() === 7) {
    let dayIndex = days.findIndex(
      (day) => day.date === weekStart.format("YYYY-MM-DD")
    );
    if (dayIndex !== -1) {
      let day = days[dayIndex];
      if (day.details.length === 0) {
        weekStart = weekStart.add(1, "day");
      }
    }
  }
  if (!selectedWeek) {
    selectedWeek = 0;
  }
  weekStart = weekStart
    .add(selectedWeek, "weeks")
    .startOf("isoWeek")
    .format("YYYY-MM-DD");
  let weekStartIndex = days.findIndex((day) => day.date === weekStart);
  let week = days.slice(weekStartIndex, weekStartIndex + 7);

  /** Hide Past Days on Client Only */
  /*
  const currentDay = moment().local().format("YYYY-MM-DD");
  const currentDayIndex = days.findIndex((day) => day.date === currentDay);
  if (currentDayIndex > weekStartIndex) {
    week = week.slice(currentDayIndex - weekStartIndex);
  }
  */
  return week;
};

const getSortedClasses = (single_classes) => {
  return single_classes.sort((a, b) =>
    moment(a.class_date).format("HH:mm") > moment(b.class_date).format("HH:mm")
      ? 1
      : -1
  );
};

const getClassesAtTime = (single_classes, time) => {
  return single_classes.filter((single_class) => {
    const hour = moment(single_class.class_date).utc().format("HH : mm");
    return hour === time;
  });
};

const validDates = (start_date, end_date) => {
  return (
    moment(start_date).isValid() &&
    moment(end_date).isValid() &&
    moment(end_date).isAfter(moment(start_date))
  );
};

const getStartNext = (layout, start_date) => {
  return moment(start_date)
    .add(1, layout)
    .startOf(layout === "week" ? "isoWeek" : "month")
    .startOf("isoWeek")
    .format("YYYY-MM-DD");
};

const getStartPrev = (layout, start_date) => {
  return moment(start_date)
    .subtract(1, layout)
    .startOf(layout === "week" ? "isoWeek" : "month")
    .format("YYYY-MM-DD");
};

const utils = {
  getMonth,
  hasClases,
  validDates,
  getStartPrev,
  getStartNext,
  getClassTypes,
  getStartDate,
  getEndDate,
  getLimitWeeks,
  getCurrentWeek,
  getClassesAtTime,
  getSortedClasses,
};

export default utils;
