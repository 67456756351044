import React, { useContext, useState } from "react";
import { ModalContext } from "../../context/ModalContext";

const CancelForm = ({ purchase, handleCancel, cancelSubscription }) => {
  const [reason, setReason] = useState("");

  const { alert } = useContext(ModalContext);

  const handleSubmit = () => {
    if (reason === "") {
      return alert("Debes indicar un motivo de cancelación.");
    }
    cancelSubscription(purchase.purchase_id, reason);
  };

  return (
    <div>
      <p>
        ¿Estás segura que deseas cancelar tu suscripción #{purchase.purchase_id}{" "}
        a {purchase.class_package.title}?
      </p>{" "}
      <p>
        Si estás en período de prueba gratis perderás acceso a tus beneficios
        inmediatamente.
      </p>
      <p>
        De lo contrario, perderás acceso a tus beneficios cuando termine tu
        ciclo actual de facturación. No se hará ningún reembolso por el último
        cargo efectuado aún y cuando solo haya pasado un minuto de haberse
        realizado.
      </p>
      <p>Esta acción NO puede deshacerse,</p>
      <label>¿Cuál es el motivo de tu cancelación?</label>
      <input
        type="text"
        value={reason}
        className="form-control mb-3"
        onChange={(e) => setReason(e.target.value)}
      />
      <button className="btn btn-danger btn-small my-4" onClick={handleSubmit}>
        Entiendo, Cancelar Mi Membresía
      </button>
      <button className="btn text-muted w-100" onClick={handleCancel}>
        Prefiero quedarme
      </button>
    </div>
  );
};

export default CancelForm;
