import React, { useContext } from "react";
import moment from "moment";
import { AppConfigContext } from "../../context/AppConfigContext";

const CustomerData = ({ user, handleEdit }) => {
  const { name, last_name, birthdate, instagram, phone, email } = user;
  const { S3_ENDPOINT } = useContext(AppConfigContext);

  const getSrc = () => {
    const { file } = user;
    let src = "icono-blanco.png";
    if (file && file !== null) {
      src = `${file.name}.${file.type}`;
    }
    return `${S3_ENDPOINT}/${src}`;
  };

  const renderBirthdate = () => {
    const birthday = moment(birthdate).utc();
    if (birthday.isValid()) {
      return birthday.format("DD MMM YYYY");
    }
    return <span className="text-danger">No has agregado tu compleaños</span>;
  };

  return (
    <div className="container-fluid px-0">
      <div className="row">
        <div className="col-12 col-md-2">
          <img src={getSrc()} alt={user.name} className="profile-image mb-4" />
        </div>
        <div className="col-12 col-md-10">
          <h4 className="border-bottom pb-2">
            {name} {last_name}
          </h4>
          <div className="small mb-1">
            <i className="fa fa-envelope me-3"></i>
            {email}
          </div>
          <div className="small mb-1">
            <i className="fa fa-birthday-cake me-3"></i>
            {renderBirthdate()}
          </div>
          <div className="small mb-1">
            <i className="fa fa-phone me-3"></i>
            {phone}
          </div>
          <div className="small mb-1">
            <i className="fab fa-instagram me-3"></i>
            {instagram}
          </div>
        </div>
      </div>

      <button onClick={handleEdit} className="btn btn-sm mt-3 btn-primary">
        <i className="fa fa-edit me-3"></i>Editar Informacion
      </button>
    </div>
  );
};

export default CustomerData;