import React from "react";
import Schedule from "../components/schedule/Schedule";

const Calendario = () => {
  return (
    <div className="container-fluid p-3">
      <Schedule isHome={true} hidePrevious />
    </div>
  );
};

export default Calendario;