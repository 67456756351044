import React from "react";
import Page from "./Page";

const PreguntasFrecuentes = () => {
  return (
    <Page title="Preguntas Frecuentes">
      
    </Page>
  );
};

export default PreguntasFrecuentes;
