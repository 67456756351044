import React from "react";
import Page from "./Page";

const Terms = () => {
  return (
    <Page title="Términos y Condiciones">
     
    </Page>
  );
};

export default Terms;
