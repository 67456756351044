import React from "react";

const ImageInputThumbnail = ({ src, handleDelete }) => {
  return (
    <div className="d-inline-block me-4 my-3 position-relative">
      <img
        src={src}
        className="my-3"
        alt="Imagen Subida"
        style={{ width: 100, height: 100, objectFit: "cover" }}
      />
      <button
        type="button"
        onClick={handleDelete}
        style={{ top: 0, right: -10, position: "absolute" }}
        className="btn btn-sm btn-outline-danger"
      >
        <i className="fa fa-trash" />
      </button>
    </div>
  );
};

export default ImageInputThumbnail;
