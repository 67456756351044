/**
 * Videos
 */
export const CLEAR_VIDEOS = "CLEAR_VIDEOS";
export const APPEND_VIDEOS = "APPEND_VIDEOS";
export const PURCHASE_NEEDED = "PURCHASE_NEEDED";
export const VIDEOS_RECEIVED = "VIDEOS_RECEIVED";
export const SINGLE_VIDEO_RECEIVED = "SINGLE_VIDEO_RECEIVED";
/**
 * Favoritos
 */
export const AGREGAR_FAVORITO = "AGREGAR_FAVORITO";
export const ELIMINAR_FAVORITO = "ELIMINAR_FAVORITO";
export const FAVORITOS_RECIBIDOS = "FAVORITOS_RECIBIDOS";
