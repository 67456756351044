import React from "react";
import Page from "./Page";

const Ubicacion = () => {
  return (
    <Page title="Ubicación">
      
    </Page>
  );
};

export default Ubicacion;
