import React, { createContext, useReducer } from "react";
import LocationsReducer from "../reducers/LocationsReducer";
import LocationsServices from "../services/LocationsServices";
import { SET_LOCATION, LOCATIONS_RECEIVED } from "../actions/locations";

const initialState = {
  locations: null,
};

export const LocationsContext = createContext(initialState);

export const LocationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LocationsReducer, initialState);

  const getLocations = () => {
    LocationsServices.getLocations().then((res) => {
      const { locations } = res.data;
      dispatch({ type: LOCATIONS_RECEIVED, payload: locations });
    });
  };

  const setLocation = (location) => {
    dispatch({ type: SET_LOCATION, payload: location });
  };

  return (
    <LocationsContext.Provider
      value={{
        ...state,
        setLocation,
        getLocations,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
};
