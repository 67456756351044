import api from "./api";

const route = "/checkout";

const CheckoutService = {
  attempt: (class_package_id, discountCode, payment_method) =>
    api.post(`${route}/attempt`, {
      class_package_id,
      discountCode,
      payment_method,
    }),
  postCheckout: (class_package_id, customer_id, discountCode) =>
    api.post(route, { class_package_id, customer_id, discountCode }),
  postPayPal: (class_package_id, discountCode) =>
    api.post(`${route}/paypal`, { class_package_id, discountCode }),
  capturePayPal: (data) => api.post(`${route}/capture`, { ...data }),
};

export default CheckoutService;
