import React from "react";

const ClassTypeCard = ({ class_type }) => {
  return (
    <div className="col-12 col-md-3 p-2">
      <div
        className="card bg-light"
      >
        <div className="border-bottom">
          <h4 className="card-title">
            {class_type.name} <i className={class_type.icon}></i>
          </h4>
        </div>
        <div className="card-body">
          <p>{class_type.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ClassTypeCard;
