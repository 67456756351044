import React, { useState, useEffect, useContext } from "react";
import { SingleClassContext } from "../../context/SingleClassContext";
import { LocationsContext } from "../../context/LocationsContext";
import ScheduleLocationPicker from "./ScheduleLocationPicker";
import ScheduleMonthSelect from "./ScheduleMonthSelect";
import ScheduleWeekSelect from "./ScheduleWeekSelect";
import ColorLegend from "../global/ColorLegend";
import ScheduleWeek from "./ScheduleWeek";
import utils from "./utils";

const Schedule = () => {
  const view = "week";
  const [location, setLocation] = useState("");

  const {
    days,
    update,
    end_date,
    start_date,
    setEndDate,
    setStartDate,
    getSchedule,
  } = useContext(SingleClassContext);

  const { locations, getLocations } = useContext(LocationsContext);

  useEffect(() => {
    const date = utils.getStartDate(view);
    setStartDate(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  useEffect(() => {
    setEndDate(utils.getEndDate(view, start_date));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date]);

  useEffect(() => {
    fetchSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date, end_date, update]);

  const fetchSchedule = () => {
    if (utils.validDates(start_date, end_date)) {
      getLocations({ start_date, end_date });
      getSchedule({ start_date, end_date });
    }
  };

  const handleNextWeek = () => {
    setStartDate(utils.getStartNext("week", start_date));
  };

  const handlePrevWeek = () => {
    setStartDate(utils.getStartPrev("week", start_date));
  };

  const handleNextMonth = () => {
    setStartDate(utils.getStartNext("month", start_date));
  };

  const handlePrevMonth = () => {
    setStartDate(utils.getStartPrev("month", start_date));
  };

  return (
    <div className="container-fluid px-0 mb-4">
      <div className="row me-0 align-items-center">
        <div className="col-12 col-md-4 mb-3">
          <ScheduleMonthSelect
            start_date={start_date}
            handleNext={handleNextMonth}
            handlePrev={handlePrevMonth}
          />
        </div>
        <div className="col-12 col-md-4 mb-3">
          <ScheduleWeekSelect
            start_date={start_date}
            handleNextWeek={handleNextWeek}
            handlePrevWeek={handlePrevWeek}
          />
        </div>
        <div className="col-12 col-md-4 mb-3">
          <ColorLegend />
          <ScheduleLocationPicker
            locations={locations}
            modifier={setLocation}
            selectedLocation={location}
          />
        </div>
      </div>
      <ScheduleWeek location={location} days={days} />
    </div>
  );
};

export default Schedule;
