import React, { useState, useContext } from "react";
import { getValue } from "../../utils";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { ClassReservationsContext } from "../../context/ClassReservationsContext";

const ClassReservationRating = ({ class_reservation_id }) => {
  const [coachStars, setCoachStars] = useState(null);
  const [claseStars, setStars] = useState(null);
  const [comment, setComment] = useState(null);

  const { user } = useContext(AuthContext);
  const { clearModal } = useContext(ModalContext);
  const { saveRating } = useContext(ClassReservationsContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      class_reservation_id,
      customer_id: user.customer_id,
      class_stars: claseStars,
      coach_stars: coachStars,
      comment,
    }

    saveRating(data);
  };

  const handleCancel = () => {
    clearModal();
  }

  const renderInput = () => {
    if (coachStars !== null) {
      return (
        <div className="my-3">
          <label className="mb-2">Agrega un comentario (opcional)</label>
          <textarea
            className="form-control mb-3"
            value={getValue({ comment }, "comment")}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      );
    }
  };

  const renderButtons = () => {
    if (coachStars !== null) {
      return (
        <div className="row">
          <div className="col-6">
            <button
              type="button"
              onClick={handleCancel}
              className="btn w-100 text-muted"
            >
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button type="submit" className="btn w-100 btn-primary">
              Calificar
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className="text-center">Elige una calificación para esta clase</p>
      <div className="container-fluid text-center mb-4">
        {new Array(5).fill(1).map((one, index) => (
          <button
            key={`clase-${index + 1}`}
            type="button"
            onClick={() => setStars(index + 1)}
            className={`btn d-inline-block ${
              claseStars > index ? "text-warning" : "text-light"
            }`}
          >
            <i className="fa fa-star"></i>
          </button>
        ))}
      </div>
      <p className="text-center">Elige una calificación para la coach</p>
      <div className="container-fluid text-center mb-4">
        {new Array(5).fill(1).map((one, index) => (
          <button
            key={`coach-${index + 1}`}
            type="button"
            onClick={() => setCoachStars(index + 1)}
            className={`btn d-inline-block ${
              coachStars > index ? "text-warning" : "text-light"
            }`}
          >
            <i className="fa fa-star"></i>
          </button>
        ))}
      </div>
      {renderInput()}
      {renderButtons()}
    </form>
  );
};

export default ClassReservationRating;
