import React from "react";
import parse from "html-react-parser";
import { formatMonto } from "../../utils";

const PaqueteCard = ({ paquete, action, buttonTitle, className }) => {
  const onSale = () => paquete.sale_price && paquete.sale_price !== null;

  const renderClassPackageTypes = () => {
    if (Array.isArray(paquete.class_package_types)) {
      if (paquete.class_package_types.length > 0) {
        return paquete.class_package_types.map(
          ({ expiration_days, class_amount, class_type }) => (
            <div key={class_type.class_type_id}>
              <p className="text-primary mb-0 bold">
              {class_amount === 100 ? <span>Clases ilimitadas de {class_type.name}</span> : <span>{class_amount} clases de {class_type.name}.</span>} 
              </p>
              <p>{expiration_days} días de vigencia.</p>
            </div>
          )
        );
      }

      return (
        <div>
          {paquete.class_amount > 0 && (
            <p className="text-primary mb-0 bold">
              {paquete.class_amount} clases
            </p>
          )}
          {paquete.expiration_days > 0 && (
            <p>{paquete.expiration_days} días de vigencia.</p>
          )}
        </div>
      );
    }
  };

  const renderDescription = () => {
    const { description } = paquete;
    if (description !== null && description !== "") {
      if (String(description).includes("<")) {
        return parse(description);
      }
      return <p className="package-description">{paquete.description}</p>;
    }
  };

  const renderShortDescription = () => {
    const { short_description } = paquete;
    if (short_description !== null && short_description !== "") {
      if (String(short_description).includes("<")) {
        return parse(short_description);
      }
      return <p className="pb-2 mb-0">{short_description}</p>;
    }
  };

  const { title, sale_price, price, is_special_event } = paquete;
  return (
    <div className={"col-12 col-md-6 col-lg-4 my-3"}>
      <div
        className={`px-3 pt-3 package-card shadow-sm no-scale position-relative ${
          is_special_event ? "bg-dark text-white" : "bg-light border"
        }`}
        style={{
          paddingBottom: '80px'
        }}
      >
        {sale_price && sale_price !== null && (
          <div className="bg-danger text-white sale-ribbon p-2 px-4 shadow-sm">
            SALE
          </div>
        )}
        <h3 className={is_special_event ? "text-warning" : ""}>{title}</h3>
        {renderShortDescription()}
        {renderDescription()}
        {renderClassPackageTypes()}
        <p className="card-title">
          {onSale() && (
            <span className="strike-through d-inline-block me-3 text-danger">
              {"$"}
              {formatMonto(price)}
            </span>
          )}
          {"$"}
          {formatMonto(onSale() ? sale_price : price)}
          {" MXN"}
        </p>
        <div
          className=""
          style={{
            position: "absolute",
            bottom: 20,
            width: "calc(100% - 48px)",
          }}
        >
     
          <button
            className={
              className ? className : "btn btn-primary btn-block w-100"
            }
            onClick={action}
          >
            {buttonTitle ? buttonTitle : "Comprar"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaqueteCard;
