import React, { useContext } from "react";
import PanelNavbar from "../components/global/PanelNavbar";
import MobileMenu from "../components/common/MobileMenu";
import { AuthContext } from "../context/AuthContext";
import MisReservaciones from "./MisReservaciones";
import Footer from "../components/global/Footer";
import MiInformacion from "./MiInformacion";
import Presenciales from "./Presenciales";
import { Router } from "@reach/router";
import MisMetodos from "./MisMetodos";
import Purchases from "./Purchases";
import Invoices from "./Invoices";
import Shop from "./Shop";
import MisCreditos from "./MisCreditos";


const Panel = () => {
  const { user, signOut } = useContext(AuthContext);

  return (
    <div className="container-fluid px-0">
      <MobileMenu signOut={signOut} user={user} />
      <PanelNavbar user={user} signOut={signOut} />
      <div className="main-panel p-xl-3">
        <Router>
          <Shop path="/paquetes" />
          <Presenciales path="/calendario" default />
          <MiInformacion path="/informacion" />
          <MisMetodos path="/metodos" />
          <MisReservaciones path="/reservaciones" />
          <Purchases path="/membresias" />
          <Invoices path="/pagos" />
          <MisCreditos path="/creditos"/>
        </Router>
      </div>
      <Footer />
    </div>
  );
};

export default Panel;
