import { Link } from "@reach/router";
import React, { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";

const CheckoutHeader = () => {
  const { S3_ENDPOINT, navbar_logo } = useContext(AppConfigContext);
  return (
    <div className="row border-bottom align-items-center bg-light pb-3 pt-3">
      <div className="container-fluid">
        <div className="container px-2">
          <div className="row align-items-center">
            <div className="col-8 col-md-10">
              <h1 className="mb-0">Checkout</h1>
            </div>
            <div className="col-4 col-md-2 text-end">
              <Link to="/">
                <img
                  src={`${S3_ENDPOINT}/${navbar_logo}`}
                  alt="logo"
                  className="logo-navbar"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutHeader;
