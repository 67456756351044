import React, { createContext, useReducer } from "react";
import SingleClassReducer from "../reducers/SingleClassReducer";
import SingleClassService from "../services/SingleClassService";
import {
  SCHEDULE_RECEIVED,
  SET_SINGLE_CLASS,
  SET_START_DATE,
  SET_END_DATE,
  SET_MONTH,
  SET_WEEK,
} from "../actions/single_class";

const initialState = {
  class_reservations: null,
  single_classes: null,
  single_class: null,
  selectedWeek: null,
  month: null,
  weeks: null,
};

export const SingleClassContext = createContext(initialState);

export const SingleClassProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SingleClassReducer, initialState);

  const setMonth = (month) => {
    dispatch({ type: SET_MONTH, payload: month });
  };

  const setWeek = (week) => {
    dispatch({ type: SET_WEEK, payload: week });
  };

  const getSchedule = (filters) => {
    SingleClassService.getSchedule(filters).then((res) => {
      const { days } = res.data;
      dispatch({ type: SCHEDULE_RECEIVED, payload: days });
    });
  };

  const getSingleClass = (single_class_id) => {
    SingleClassService.getSingleClass(single_class_id).then((res) => {
      const { single_class } = res.data;
      dispatch({ type: SET_SINGLE_CLASS, payload: single_class });
    });
  };

  const setClase = (single_class) => {
    dispatch({ type: SET_SINGLE_CLASS, payload: single_class });
  };

  const clearClase = () => {
    dispatch({ type: SET_SINGLE_CLASS, payload: null });
  };

  const setStartDate = (start_date) => {
    dispatch({ type: SET_START_DATE, payload: start_date });
  };

  const setEndDate = (end_date) => {
    dispatch({ type: SET_END_DATE, payload: end_date });
  };

  return (
    <SingleClassContext.Provider
      value={{
        ...state,
        setWeek,
        setClase,
        setMonth,
        clearClase,
        setEndDate,
        getSchedule,
        setStartDate,
        getSingleClass,
      }}
    >
      {children}
    </SingleClassContext.Provider>
  );
};
