import React from "react";
import moment from "moment";
import { formatMonto } from "../../utils";
import StatusBadge from "../global/StatusBadge";

const InvoiceRow = ({ invoice }) => {
  const renderClassPackage = () => {
    if (invoice.class_package && invoice.class_package !== null) {
      return invoice.class_package.title;
    }
  };

  const renderNext = () => {
    if (invoice.purchase && invoice.purchase !== null) {
      if (invoice.purchase.status === "active") {
        return moment(invoice.createdAt).utc()
          .add(
            invoice.purchase.subscription_interval,
            invoice.purchase.subscription_period
          )
          .format("DD MMM YYYY");
      }
    }
  };

  return (
    <tr>
      <td>#{invoice.invoice_id}</td>
      <td>#{invoice.purchase_id}</td>
      <td>{renderClassPackage()}</td>
      <td>
        {"$"}
        {formatMonto(invoice.total_payment)}
        {" MXN"}
      </td>
      <td>{moment(invoice.createdAt).format("DD MMM YYYY HH:mm")}</td>
      <td>{renderNext()}</td>
      <td>
        <StatusBadge {...invoice} />
      </td>
    </tr>
  );
};

export default InvoiceRow;
