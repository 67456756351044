import { Link } from "@reach/router";
import React, { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";

const HomeCoachCard = ({ coach }) => {
  const { S3_ENDPOINT, navbar_logo } = useContext(AppConfigContext);

  const getSrc = () => {
    if (coach.file && coach.file !== null) {
      return `${S3_ENDPOINT}/${coach.file.name}.${coach.file.type}`;
    }
    return `${S3_ENDPOINT}/${navbar_logo}`;
  };

  const { name, last_name } = coach;
  
  return (
    <Link
      to={`/coach/${coach.instructor_id}`}
      className="col-12 col-md-4 col-lg-3 col-xl-2 p-4 no-decoration text-dark"
    >
      <div className="card bg-white shadow-sm px-0 py-0 text-center">
        <img src={getSrc()} className="mw-100 w-100" alt={coach.name} />
        <div className="pt-3">
          <h4>
            {name} {last_name}
          </h4>
        </div>
      </div>
    </Link>
  );
};

export default HomeCoachCard;
