import React from "react";

const PanelHeader = ({ title }) => {
  return (
    <div className="row align-items-center mx-0 border-bottom pb-2 mb-3">
      <div className="container-fluid px-0">
        <h1 className="h2 mb-0 mt-1">{title}</h1>
      </div>
    </div>
  );
};

export default PanelHeader;