import { INSTRUCTORS_RECEIVED, SET_INSTRUCTOR } from "../actions/instructors";

const InstructorsReducer = (state, { type, payload }) => {
  switch (type) {
    case INSTRUCTORS_RECEIVED:
      return { ...state, instructors: payload, instructor: null };
    case SET_INSTRUCTOR:
      return { ...state, instructor: payload };
    default:
      return { ...state };
  }
};

export default InstructorsReducer;
