import React from "react";
import ScheduleClass from "./ScheduleClass";
import moment from "moment";
import "./schedule.css";

const ScheduleDay = ({ day, single_classes, location }) => {
  const renderSingleClasses = () => {
    if (Array.isArray(single_classes)) {
      let current_single_classes = [...single_classes];
      if (location && location !== null) {
        current_single_classes = current_single_classes.filter(
          (single_class) =>
            parseInt(single_class.location_id) === parseInt(location)
        );
      }
      if (current_single_classes.length === 0) {
        return <p className="show-mobile">No hay clases programadas.</p>;
      }
      current_single_classes = current_single_classes.sort((a, b) =>
        a.class_date > b.class_date ? 1 : -1
      );
      return current_single_classes.map((single_class) => (
        <ScheduleClass
          key={single_class.single_class_id}
          single_class={single_class}
        />
      ));
    }
  };

  const renderDay = () => {
    const day_string = moment(day.date).utc().format("dd");
    switch (day_string) {
      case "Tu":
        return "MARTES";
      case "We":
        return "MIÉRCOLES";
      case "Th":
        return "JUEVES";
      case "Fr":
        return "VIERNES";
      case "Sa":
        return "SÁBADO";
      case "Su":
        return "DOMINGO";
      default:
        return "LUNES";
    }
  };

  return (
    <div className="schedule-col px-0 bg-light border text-center">
      <div className="row p-2">
        <div className="container-fluid text-center">
          {moment(day.date).utc().format("DD")}
        </div>
      </div>
      <div className="row bg-dark text-white mx-0">
        <div className="container-fluid text-center bold">{renderDay()}</div>
      </div>
      {renderSingleClasses()}
    </div>
  );
};

export default ScheduleDay;
