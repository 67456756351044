/**
 * User
 */
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const USER_CREATED = "USER_CREATED";
export const EDITAR_USUARIO = "EDITAR_USUARIO";
export const GUARDAR_USUARIO = "GUARDAR_USUARIO";
export const CUENTA_RECIBIDA = "CUENTA_RECIBIDA";
export const SET_PROPIEDAD_USER = "SET_PROPIEDAD_USER";
export const SET_PROPIEDAD_LOGIN = "SET_PROPIEDAD_LOGIN";
