import React, { createContext, useReducer, useContext } from "react";
import { CUSTOMERS_RECIBIDOS, SET_CUSTOMER } from "../actions";
import CustomerReducer from "../reducers/CustomerReducer";
import CustomerService from "../services/CustomerService";
import { ModalContext } from "./ModalContext";

const initialState = {
  customers: null,
  customer: null,
};

export const CustomerContext = createContext(initialState);

export const CustomerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CustomerReducer, initialState);

  const { success, clearModal } = useContext(ModalContext);

  const getCustomersByQuery = (query) => {
    CustomerService.getCustomersByQuery(query).then((res) => {
      const { customers } = res.data;
      dispatch({ type: CUSTOMERS_RECIBIDOS, payload: customers });
    });
  };

  const getAllCustomers = (query) => {
    dispatch({ type: CUSTOMERS_RECIBIDOS, payload: null });
    CustomerService.getAllCustomers(query).then((res) => {
      const { customers } = res.data;
      dispatch({ type: CUSTOMERS_RECIBIDOS, payload: customers });
    });
  };

  const getCustomer = (customer_id) => {
    CustomerService.getCustomer(customer_id).then((res) => {
      dispatch({ type: SET_CUSTOMER, payload: res.data });
    });
  };

  const extenderAcceso = (
    customer_id,
    package_class_id,
    package_days,
    is_gift,
    payment_method_id,
    bill
  ) => {
    CustomerService.extenderAcceso(
      customer_id,
      package_class_id,
      package_days,
      is_gift,
      payment_method_id,
      bill
    ).then(() => {
      success("¡Acceso agregado!");
      getCustomer(customer_id);
      clearModal();
    });
  };

  const revokeAccess = (purchase_id, customer_id) => {
    CustomerService.revokeAccess(purchase_id).then((res) => {
      getCustomer(customer_id);
      success("¡Acceso eliminado!");
      clearModal();
    });
  };

  const removeClasses = (customer_id, amount) => {
    CustomerService.removeClasses(customer_id, amount).then(() => {
      getCustomer(customer_id);
      success("¡Clases Restadas!");
      clearModal();
    });
  };

  const clearCustomer = () => {
    dispatch({ type: SET_CUSTOMER, payload: null });
  };

  return (
    <CustomerContext.Provider
      value={{
        ...state,
        getCustomer,
        getAllCustomers,
        getCustomersByQuery,
        extenderAcceso,
        clearCustomer,
        revokeAccess,
        removeClasses,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};
