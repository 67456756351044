import api from "./api";
import { getArgs } from "../utils";

const route = "/single_class";

const SingleClassService = {
  getSchedule: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getSingleClass: (class_instructor_id) =>
    api.get(`${route}/single/${class_instructor_id}`),
  updateGuestName: (class_reservation_id, name) =>
    api.put(`${route}/guest`, { class_reservation_id, name }),
};

export default SingleClassService;
